@keyframes star {
  0%,100% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.9);
  }


}
@keyframes shadow {
  0% {
    box-shadow: 0 0 170px #fd34e4;
  }
  75% {
    box-shadow: 0 0 300px #fd34e4;
  }
  100% {
    box-shadow: 0 0 170px #fd34e4;
  }

}

.jackpot-wrap {
  width: 100%;
  max-width: 400px;
  aspect-ratio: 1;
  border-radius: 50%;
  color: #fff;
  position: relative;
  animation: shadow 1.8s infinite linear;
  //background: rgb(63,0,43);
  //background: linear-gradient(175deg, rgba(63,0,43,1) 0%, rgba(123,16,88,1) 100%);

  box-shadow: 0 0 178px #fd34e4;
  background: rgb(147 73 138);
  background: linear-gradient(175deg, rgb(44 1 30) 0%, rgb(118 22 87) 100%);



  .star {
    position: absolute;
    top: -39px;
    max-width: 80px;
    animation: star 1.8s infinite;

    &.star-center {
      left: calc(50% - 40px);

    }
    &.star-left {
      left: 27%;
      top: -6px;
      max-width: 60px;
    }
    &.star-right {
      right: 27%;
      top: -6px;
      max-width: 60px;
    }
  }
  .win {
    text-align: center;
    padding-top: 20%;
    font-size: 2rem;
  }
  .amount {
    font-size: 4rem;
    text-align: center;
    padding-top: 15px;
    position: relative;
    span {
        background: #fff;
        padding: 10px 20px;
        border-radius: 100px;
        color: #631148;
    }
    &:before {
      content: attr(data-text);
      top: 6px;
      left: 50%;
      font-size: 14px;
      position: absolute;
      transform: translateX(-50%);
      background: #fdd835;
      padding: 2px 15px;
      border-radius: 50px;
      color: #000;
      font-weight: bold;
    }
  }
  .lines {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .large {
      display: inline-block;
      width: 40%;
      border-bottom: 2px solid #fff;
      padding-top: 20px;
    }
    .small {
      display: inline-block;
      width: 20%;
      border-bottom: 2px solid #fff;
      padding-top: 15px;
    }
    > div {
      display: flex;
    }
    .star-bottom {
      max-width: 30px;
      padding: 5px;
      margin-top: 20px;
      filter: brightness(6.5);
    }
  }
  .close {
    text-align: center;
    padding-top: 6%;
    transition: all ease-in-out .3s;
    &:hover {
      cursor: pointer;
      //font-weight: bold;
      transform: scale(1.2);
      color: #ffc7fd;
    }
  }
}
