ul.top_tabs {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 5px;
  color: #9d9d9d;
  font-size: 12px;
  border-radius: 4px;
  background-color: var(--bg-info);
  li {
    flex: 1;
    text-align: center;

    padding: 4px 10px;
    cursor: pointer;
    &.active {
      background: #363944;
      color: #bdbdbd;
      border-radius: var(--border-radius);
    }
    &:hover:not(.active){
      color: #bdbdbd;
    }
  }
}

.top-loader {
  position: relative;
  min-height: 200px;
  .loader {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    perspective: 800px;
    background: none;
  }

  .inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 1s linear infinite;
    border-bottom: 3px solid #EFEFFA;
  }

  .inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 1s linear infinite;
    border-right: 3px solid #EFEFFA;
  }

  .inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 1s linear infinite;
    border-top: 3px solid #EFEFFA;
  }


}

.history-my {
  .more-ticket {
    width: 100%;
    text-align: center;
    padding: 10px;
    button {
      border: none;
      border-radius: 50px;
      padding: 10px 20px;
      background: #55515c;
      color: #b8b8b9;
      &:hover {
        cursor: pointer;
        background: #66636c;
        color: #e2e2e5;
      }
    }
  }
}
