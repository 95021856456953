@keyframes scale {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

#results {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  max-width: 350px;
  padding: 1px 0 5px;
  color: #fff;
  margin: 0 auto;
  z-index: 9;

  .ball {
    flex: 0 0 10%;
    .b {
      height: 34px;
      width: 34px;
      background: #a140c3;
      font-size: 12px;
    }

    &.hot {
      .b {
        box-shadow: inset 0 0 24px #b55dd3;
        color: #efc0ff;
        background: #7e3b95;
        text-shadow: 0 1px #c46fe1;
        border: 1px solid #aa62c3;
      }
    }
    &.ice {
      .b {
        box-shadow: inset 0 0 24px #95b0ed;
        color: #cac6ff;
        background: #5650cb;
        text-shadow: 0 1px #c9c6ff;
        border: 1px solid #8d85ff;
      }
    }

    &.result {
      animation: scale 1s;
    }
  }

  @media (max-width: 400px) {
    .ball {
      .b {
        height: 24px;
        width: 24px;
      }
    }
  }

}
