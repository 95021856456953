.ticket-card {
  border-radius: var(--border-radius);
  overflow: hidden;
  color: #9d9d9d;
  margin-bottom: 5px;
  .bet-item {
    background-color: #413e46;
    padding: 5px;
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      li {
        flex: 1;
        display: flex;
        align-items: center;
        &.user {
          span {color: #b9b8b7;}
          i {
            width: 24px;
            height: 24px;
            background: #9b7d28;
            display: inline-block;
            border-radius: 20px;
            margin-right: 5px;
          }
        }
        &.bet {
          justify-content: center;
          color: #d5d5d5;
        }
        &.win {
          span {
            min-width: 30px;
            background: #55515c;
            text-align: center;
            border-radius: var(--border-radius);
            padding: 0 5px;
          }
        }
        &.action {
          justify-content: right;
          max-width: 60px;
          span {font-size: 11px}
          .arrow {
            width: 20px;
            text-align: center;
          }
        }
      }
    }
  }
  .bet-info {
    background-color: #343238;
    padding: 5px;
    .row-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .actions {
        display: flex;
        align-items: center;
        .copy {
          min-width: 30px;
          background: #55515c;
          text-align: center;
          border-radius: var(--border-radius);
          padding: 0 5px;
          img {mix-blend-mode: soft-light;}
          &:hover {
            cursor: pointer;
            filter: brightness(1.3);
          }
        }
        .t_count {
          min-width: 30px;
          background: #55515c;
          text-align: center;
          border-radius: var(--border-radius);
          padding: 0 5px;
          margin-left: 5px;
          span {color: #fff}
        }
      }

    }
    ul.row-2 {
      list-style: none;
      display: flex;
      padding-top: 10px;
      li {
        flex: 0 0 10%;
        .ball {
          width: 24px;
          height: 24px;
          border-radius: 50px;
          background: #1e1b22;
          text-align: center;
          line-height: 24px;
          font-size: 12px;
        }
        &.active {
          .ball {
            background: #3b8d45;
            color: #fff;
          }
        }
      }
    }
  }
}
