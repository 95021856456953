@mixin miniScroll($thumb:rgba(255, 255, 255, 0.4), $track: #101217) {
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px $track;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb;
    outline: 0;
  }
}


@mixin hotBall {
  background-color: #401d4c;
  color: #ccaed7;
}
@mixin hotBallActiveHover {
  box-shadow: inset 0 0 24px #b55dd3;
  color: #efc0ff;
  background: #7e3b95;
  text-shadow: 0 1px #c46fe1;
  border: 1px solid #aa62c3;
}
@mixin iceBall {
  background-color: #312160;
  color: #aca9d7;
}
@mixin iceBallActiveHover {
  box-shadow: inset 0 0 24px #95b0ed;
  color: #cac6ff;
  background: #5650cb;
  text-shadow: 0 1px #c9c6ff;
  border: 1px solid #8d85ff;
}
