@import "./src/assets/scss/mixins";

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

ul.history_tabs {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 5px;
  color: #9d9d9d;
  font-size: 12px;
  border-radius: 4px;
  background-color: var(--bg-info);

  li {
    flex: 1;
    text-align: center;

    padding: 4px 10px;
    cursor: pointer;
    &.active {
      background: #363944;
      color: #bdbdbd;
      border-radius: var(--border-radius);
    }
    &:hover:not(.active){
      color: #bdbdbd;
    }
  }
}
.section-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 5px;
  color: #b1b5b7;

  span {
    color: #7a7a7a;
  }

  button {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 20px;
    border: 1px solid #454858;
    background: #363944;
    color: #9d9d9d;
    max-height: 22px;
    img {margin-left: 5px;}
    &:hover {
      cursor: pointer;
      filter: brightness(1.2);
    }
    &.active {
      border: 1px solid #4fb58f;
      background: #1f9971;
      color: #fff;
      img {width: 11px}
    }
  }
}
.history-edition {
  .ed-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    background: #2d2d32;
    margin: 6px 0;
    color: #c2c2c4;
    ul {
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style: none;
      padding: 4px 0;
      padding-right: 5px;
      li {
        flex: 1 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px 0;
        .bal {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #40424a;
          &.ice { @include iceBallActiveHover; color: #f5f4ff;}
          &.hot { @include hotBallActiveHover; color: #f9e6ff;}
        }
      }
    }
    .edition-id {
      padding: 10px;
      min-width: 75px;
      color: #918e8e;
    }
    &:first-child {margin-top: 0;}
    &:last-child {margin-bottom: 0;}
  }
}
.history-loader {
  position: relative;
  min-height: 200px;
  .loader {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    perspective: 800px;
    background: none;
  }

  .inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 1s linear infinite;
    border-bottom: 3px solid #EFEFFA;
  }

  .inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 1s linear infinite;
    border-right: 3px solid #EFEFFA;
  }

  .inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 1s linear infinite;
    border-top: 3px solid #EFEFFA;
  }


}
.history-stats {
  ul {
    li {
      display: flex;
      flex-direction: row;
      background: #2a2b31;
      border-radius: 20px;
      height: 20px;
      align-items: center;
      margin: 0 0 6px;
      color: #ccc;
      .stat_ball {
        width: 20px;
        background: #3b3d44;
        border-radius: 20px;
        text-align: center;
        line-height: 20px;
        font-size: 11px;
      }
      .stat_line {
        flex: 1 1;
        height: 3px;
        margin: 0 6px;
        position: relative;

        //width: 93.75%;

        i {
          display: inline-block;
          height: 100%;
          background-color: rgb(200, 58, 122);
          border-radius: 4px;
          position: absolute;
          left: 0;
          top:0;
        }
      }
      .stat_count {
        min-width: 14px;
        margin-right: 5px;
        font-size: 12px;
      }

    }
  }
}
