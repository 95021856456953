.customLoader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#131b26, 0.9);
  animation: fadeIn 0.15s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  z-index: 999;
}
.svg-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  //background: #16ab4e;
  //border-radius: 30px;
  //display: none;
  svg {
    padding: 10px;
    fill: #000000;
    height:100%;
    width: 100%;
  }

  &.inputLoader {
    top:50%;
    transform: translateY(-50%);
    svg {
      fill:#d6dbeb;
      height: 30px;
    }
  }
}