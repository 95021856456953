.autoPlayConfig {
  position: absolute;
  bottom: calc(100% + 15px);
  /* width: 100%; */
  width: 200px;
  min-height: 212px;
  background: rgb(36 35 31 / 89%);
  z-index: 5;
  right: 0;
  border-radius: var(--border-radius);
  box-shadow: 0 0 30px #000;
  padding: 10px 15px;
  .a_box {
    padding: 5px 0;
  }
  .title {
    padding-bottom: 5px;
  }
  .spinCount {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap:5px;
    li {
      flex: 1 calc(20% - 5px);
      background: #455960;
      color: #ccc;
      border-radius: var(--border-radius);
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        cursor: pointer;
        color: #fff;
        background: #54666c;
      }
      &.active {
        background: #b98b08;
        color: #fff;
      }
    }
  }
  .randomBet {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap:5px;
    li {
      flex: 1 calc(20% - 5px);
      background: #455960;
      color: #ccc;
      border-radius: var(--border-radius);
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        cursor: pointer;
        color: #fff;
        background: #54666c;
      }
      &.active {
        background: #b98b08;
        color: #fff;
      }
    }
  }
  .btn {
    padding: 15px 0 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    button {
      color: #fff;
      white-space: nowrap;
      width: auto !important;
      height: unset !important;
      padding: 10px 20px;
    }
  }
}
