.header {
  width: calc(100% - 20px);
  height: 40px;
  margin: 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    display: flex;
    align-items: center;
    margin-right: 50px;
  }

  .jackpots {
    display: flex;
    align-items: center;
    color: #fff;
    span {
      font-size: 18px;
      color: #63ffb7;
    }
    .j_wrap {
      display: flex;
      align-items: center;
    }
    .j_box {
      display: flex;
      align-items: center;
      border: 1px dashed rgba(45,214,130,.2);
      margin-left: 10px;
      padding: 4px 10px;
      border-radius: 3px;
      min-height: 32px;
      .title {
        text-transform: uppercase;
        padding-right: 10px;
        color: #ffd96f;
        line-height: 13px;
      }
      .jackpot {
        color: #2dd682;
        font-size: 22px;
        line-height: 22px;
        font-family: Consolas,monospace;
        font-weight: 600;
      }
    }
    .jack_loader {
      position: relative;
      width: 40px;
      height: 20px;
      svg {
        max-height: 20px;
        padding: 0;
      }
    }
  }

  .balance {
    display: flex;
    align-items: center;
    background: #484848;
    border-radius: 3px;
    padding-left: 7px;
    span {
      color: #d9d9d9;
      padding-right: 10px;
      font-size: 18px;
    }
    img {
      max-height: 20px;
      margin-right: 5px;
    }
  }

  .log_out {
    display: flex;
    align-items: center;
    span {
      color: #d7d3d3;;
      padding-right: 10px;
    }
    img {
      max-height: 20px;
    }
    &:hover {
      cursor: pointer;
      img {
        filter: invert(35%) sepia(100%) saturate(41.5);
      }
      span {
        color: #fff;
      }
    }
  }
}
