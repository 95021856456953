.ticket-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 10px 10px 5px;

  .count {
    color: #fff;
    img {margin-right: 5px}
  }

  .prev {
    display: flex;
    align-items: center;
    .tour_id {
      margin-right: 10px;
      padding: 2px 5px;
      border-radius: 3px;
      font-size: 10px;
      background: #3d3944;
      span {
        &:first-child {color: #7c7b7c}
        &:last-child {color: #6cb0bb; padding-left: 5px}
      }
      .tour_loader {
        position: relative;
        display: inline-block;
        min-width: 22px;
        height: 10px;
        margin-left: 5px;
        .svg-loader svg {padding: 0}
      }
    }
  }

  button {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 20px;
    border: 1px solid #454858;
    background: #363944;
    color: #9d9d9d;
    img {margin-right: 5px; width: 14px}
    &:hover {
      cursor: pointer;
      filter: brightness(1.2);
    }
    &.active {
      border: 1px solid #4fb58f;
      background: #1f9971;
      color: #fff;
      img {width: 11px}
    }
  }


}

.ticket-list {
  .empty_tickets {
    text-align: center;
    color: #9b9b9b;
    padding: 50px 0;
    background: #131416;
  }
}
