//$bgColors: #0e415a; // #3c7997
//$itemBgColor: #26698b;
//$gridBg:#3d94bf;

//$bgColors:#343238; // #3c7997
//$itemBgColor: #232226;
//$gridBg:#413e46;
//$textColor:#d0d0d1;

//$bgColors:#343238; // #3c7997
//$head-bg:#605b6a;
//$itemBgColor: #504c58;
//$gridBg:#343238;
//$textColor:#d0d0d1;


//$bgColors:#343238; // #3c7997
//$head-bg:#605b6a;
//$itemBgColor: #504c58;
//$gridBg:#343238;
//$textColor:#d0d0d1;

$bgColors:#343238; // #3c7997

$head-bg:#413e46;
$item-bg: #46434c;
$gridBg:#343238;
$textColor:#d0d0d1;


#my-tickets {
  //display: flex;
  //min-height: 300px;
  margin-bottom: 10px;
  //background: #247676;
  background: $bgColors;
  //background: #332e8b;
  border-radius: var(--border-radius);
  padding: 10px;
  ul.info {
    display: flex;
    list-style: none;
    max-width: 300px;
    flex-direction: row;
    justify-content: space-between;
    color: rgba(255,255,255,.70);
    li {
      p {font-size: 12px}
      span {
        color: #fff;
      }
    }
  }
  .list {
    padding-top: 10px;
    .item {
      background: $item-bg;
      border: 1px solid #4a4949;
      border-radius: 8px;
      margin-bottom: 10px;
      overflow: hidden;
      //box-shadow: 0 0 10px #4a9cc57d;

      .head {
        display: flex;
        justify-content: space-between;
        background: $head-bg;
        color: $textColor;
        padding: 10px;
        span {
          span {color: #ffc00e}
        }
      }
      ul.bal_box {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap:5px;
        border-top: 1px solid #57535e;
        padding: 10px 5px;
        li {
          height: 24px;
          width: 24px;
          background: $gridBg;
          color: $textColor;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          &.active {
            background: #3b8d45;
            color: #fff;
          }
        }
      }
      h4 {
        padding: 0 10px;
        color: $textColor;
      }
      ul.grid {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        gap:5px;
        padding: 5px 10px 10px;
        li {
          flex: 1 calc(50% - 5px);
          display: flex;
          justify-content: space-between;
          background: $gridBg;
          border-radius: 50px;
          color: $textColor;
          padding: 1px 10px;
          &.active {
            background: #3b8d45;
            color: #fff;
          }
        }
      }
      &:last-child {margin: 0}
    }
  }
}
