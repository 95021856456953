#notify {
  position: fixed;
  bottom: 20px;
  right: 20px;
  //width: 100%;
  max-width: 363px;
  z-index: 8;

  .notify-item {
    box-shadow: 0 0 20px #0000007d;
    background: #d79e1d;
    color: #523603;
    border-radius: 4px;
    max-width: 100%;
    padding: 15px 25px;
    text-align: center;
    font-size: 13px;

    &[data-type="Success"] {
      background: #1ca553;
      color: #ffffff;
      box-shadow: inset 0 0 24px #26d76d;
    }
    &[data-type="Danger"] {
      background: #dc3545;
      color: #ffffff;
      box-shadow: inset 0 0 24px #f51d2f;
    }
    &[data-type="Warning"] {
      background: #cd9d0e;
      color: #ffffff;
      box-shadow: inset 0 0 24px #ffd85d;
    }

  }
}
